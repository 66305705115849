$( document ).on('turbolinks:load', function() {
    if (document.querySelector('.discount-type-select')) {
        document.querySelectorAll(".discount-type-select .form-check input").forEach(item => {
            item.addEventListener('click', event => {
                let icon_content = (event.target.value === 'percentage' ? "%" : "CNY");
                document.querySelector("#discount-type-icon").innerHTML = icon_content;

                let value_input_field = document.getElementById('discount_value');

                if (event.target.value === 'percentage') {
                    value_input_field.setAttribute('min', 0);
                    value_input_field.setAttribute('max', 100);

                    document.querySelector("#input-group-discount-euro").classList.add('d-none')
                } else {
                    value_input_field.setAttribute('min', 0);
                    value_input_field.removeAttribute('max');

                    document.querySelector("#input-group-discount-euro").classList.remove('d-none')
                }


            })
        });
    }
});
