const {ORDER_NUMBER_DIGITS} = require("../packs/application");

$( document ).on('turbolinks:load', function() {
    let order_number_input = $(".order-scan-form #order_order_number_scan");

    order_number_input.focus();
    order_number_input.focusout(function (e) {
        order_number_input.focus();
    });

    order_number_input.bind('input', function (e){
        let input_value = order_number_input.val();

        if(input_value.length >= ORDER_NUMBER_DIGITS) {

            let input_match_data = input_value.match(/.*(?<order_number>BA[A-Z0-9]{8}).*/)
            if(input_match_data){
                let order_number = input_match_data.groups.order_number
                $(".order-scan-form #order_order_number").val(order_number)
                console.log("Order num: ", order_number);
                Rails.fire($(".order-scan-form")[0], 'submit');
            }
        }
    });
});
