const {ORDER_NUMBER_DIGITS} = require("../packs/application");

$( document ).on('turbolinks:load', function() {
    let order_number_input = $(".preview-order-form #order_order_number");

    order_number_input.focus();

    order_number_input.bind('input', function (e){
        e.preventDefault();
        if(order_number_input.val().length == ORDER_NUMBER_DIGITS) {
            Rails.fire($(".preview-order-form")[0], 'submit');
        }
    });
});


