// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled
require("turbolinks").start()
require("@rails/ujs").start()
require("jquery")
require("local-time").start()
require("select2/dist/js/select2")

$( document ).on('turbolinks:load', function() {
    require("@coreui/coreui/dist/js/coreui.min.js")

    $('.js-select:not(.select2-hidden-accessible)').select2({
        width: 'style',
    });
});

export const ORDER_NUMBER_DIGITS = 10

require("../src/utils.js")
require("../src/order_scanner.js")
require("../src/bulk_packages.js")
require("../src/discounts.js")

import Rails from "@rails/ujs";
window.Rails = Rails;

const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$( document ).on('turbolinks:load', function() {
    $("[data-link]").click(function (e) {
        if (e.target.tagName.toLowerCase() !== 'a' && e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'span'){
            Turbolinks.visit($(this).data("link"));
        }
    });
});
